"use client";

import { FrontPageLink } from "../FrontPageLink/FrontPageLink.component";
import { SiteLogo } from "../SiteLogo/SiteLogo.component";
import { useSite } from "@/contexts/site/site.context";
import clsx from "clsx";
type LogoProps = {
  readonly className?: string;
  readonly size?: "lg" | "sm";
  readonly primaryClassName?: string;
};
export function Logo({
  className,
  primaryClassName = "fill-site-logo-primary",
  size = "lg"
}: LogoProps) {
  const {
    id: siteId,
    name: siteName
  } = useSite();
  return <>
      {}
      <FrontPageLink position="logo" data-sentry-element="FrontPageLink" data-sentry-source-file="Logo.component.tsx">
        <span className="sr-only">{siteName}</span>
        <SiteLogo className={clsx("w-auto max-w-full", className)} contextId="header" primaryClassName={primaryClassName} siteId={siteId} size={size} data-sentry-element="SiteLogo" data-sentry-source-file="Logo.component.tsx" />
      </FrontPageLink>
    </>;
}