import { regularButtonCommonClasses, regularButtonVariantClasses } from "../regular-button-class-names";
import type { CommonButtonProps } from "../regular-button.types";
import { Spinner } from "@/components/Spinner/Spinner.component";
import { clsx } from "clsx";
import type { MouseEventHandler } from "react";
export type RegularButtonProps = CommonButtonProps & {
  readonly onClick?: MouseEventHandler<HTMLButtonElement>;
  readonly type?: "button" | "submit";
  readonly isPending?: boolean;
  readonly testId?: string;
};
export function RegularButton({
  children,
  className,
  disabled = false,
  isPending = false,
  onClick,
  position,
  testId = "regular-button",
  type = "button",
  variant = "primary"
}: RegularButtonProps) {
  return <button className={clsx(className, regularButtonCommonClasses, regularButtonVariantClasses[variant])} data-k5a-pos={position} data-testid={testId} disabled={isPending || disabled} onClick={onClick} type={type === "button" ? "button" : "submit"} data-sentry-component="RegularButton" data-sentry-source-file="RegularButton.component.tsx">
      <span className={isPending ? "invisible" : "visible"}>{children}</span>

      {isPending ? <Spinner className="absolute h-2 w-2 fill-primary text-whisper group-disabled:fill-silver" /> : null}
    </button>;
}