import { ToolbarMenuDenseItem } from "./ToolbarMenuDenseItem.component";
import { OutlineClockIcon } from "@/components/Icons/components/HeroIcons/OutlineClockIcon.component";
import { Link } from "@/components/Link/Link.component";
import { latestPageLink } from "@/components/page-links.constants";
type ToolbarMenuButtonLatestNewsProps = {
  readonly strokeWidth: number;
};
export function ToolbarMenuButtonLatestNews({
  strokeWidth = 1
}: ToolbarMenuButtonLatestNewsProps) {
  return <Link className="toolbar-menu-item" href={latestPageLink} data-sentry-element="Link" data-sentry-component="ToolbarMenuButtonLatestNews" data-sentry-source-file="ToolbarMenuButtonLatestNews.component.tsx">
      <ToolbarMenuDenseItem label={{
      da: "Seneste",
      de: "Aktuelle",
      en: "Latest",
      no: "Siste",
      sv: "Senaste"
    }} data-sentry-element="ToolbarMenuDenseItem" data-sentry-source-file="ToolbarMenuButtonLatestNews.component.tsx">
        <OutlineClockIcon className="h-3 w-3" strokeWidth={strokeWidth} data-sentry-element="OutlineClockIcon" data-sentry-source-file="ToolbarMenuButtonLatestNews.component.tsx" />
      </ToolbarMenuDenseItem>
    </Link>;
}