"use client";

import { RegularLinkButton } from "@/components/Button/RegularLinkButton/RegularLinkButton.component";
import { Translation } from "@/components/Translation/Translation.component";
import clsx from "clsx";
import { usePathname, useSearchParams } from "next/navigation";
type BuyButtonProps = {
  readonly className?: string;
  readonly theme?: "primary" | "white";
};
export function BuyButton({
  className,
  theme = "primary"
}: BuyButtonProps) {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const shopParams = new URLSearchParams();
  shopParams.append("redirectUrl", `${pathname}?${searchParams.toString()}`);
  return <RegularLinkButton className={clsx(className)} href={`/shop?${shopParams.toString()}`} position="bbtn" variant={theme === "primary" ? "primary" : "outline-inverse"} data-sentry-element="RegularLinkButton" data-sentry-component="BuyButton" data-sentry-source-file="BuyButton.component.tsx">
      <Translation da="Køb" de="Kaufen" en="Buy" no="Kjøp" sv="Köp" data-sentry-element="Translation" data-sentry-source-file="BuyButton.component.tsx" />
    </RegularLinkButton>;
}