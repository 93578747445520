import { ToolbarMenuDenseItem } from "./ToolbarMenuDenseItem.component";
import { OutlineSearchIcon } from "@/components/Icons/components/HeroIcons/OutlineSearchIcon.component";
import { Link } from "@/components/Link/Link.component";
import { archivePageLink } from "@/components/page-links.constants";
type ToolbarMenuButtonSearchProps = {
  readonly strokeWidth: number;
};
export function ToolbarMenuButtonSearch({
  strokeWidth = 1
}: ToolbarMenuButtonSearchProps) {
  return <Link className="toolbar-menu-item" href={archivePageLink} data-sentry-element="Link" data-sentry-component="ToolbarMenuButtonSearch" data-sentry-source-file="ToolbarMenuButtonSearch.component.tsx">
      <ToolbarMenuDenseItem label={{
      da: "Søg",
      de: "Suchen",
      en: "Search",
      no: "Søk",
      sv: "Sök"
    }} data-sentry-element="ToolbarMenuDenseItem" data-sentry-source-file="ToolbarMenuButtonSearch.component.tsx">
        <OutlineSearchIcon className="h-3 w-3" strokeWidth={strokeWidth} data-sentry-element="OutlineSearchIcon" data-sentry-source-file="ToolbarMenuButtonSearch.component.tsx" />
      </ToolbarMenuDenseItem>
    </Link>;
}