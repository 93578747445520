import type { HeaderModel } from "../../models/header-model.types";
import { MenuItem } from "../MenuItem/MenuItem.component";
import { ToolbarMenu } from "../ToolbarMenu/ToolbarMenu.component";
import { NavigationDropdown } from "./components/NavigationDropdown.component";
import { clsx } from "clsx";
type NavigationProps = {
  readonly className?: string;
  readonly menuItems: ReadonlyArray<HeaderModel.MenuItem>;
  readonly theme?: "primary" | "white";
  readonly pathname: string;
};
export function Navigation({
  className,
  menuItems,
  pathname,
  theme = "primary"
}: NavigationProps) {
  return <nav className={className} data-sentry-component="Navigation" data-sentry-source-file="Navigation.component.tsx">
      <div className={clsx("container flex w-full items-stretch justify-between", theme === "primary" && "border-b-6 border-t border-solid border-b-grid-primary border-t-silver bg-white")}>
        <ul className="relative -ml-1 flex min-w-0 flex-1 items-stretch">
          {menuItems.map((menuItem, index) => {
          const {
            children,
            key,
            name,
            url
          } = menuItem;
          const isActive = pathname.includes(url);
          if (children.length > 0) {
            return <li className={clsx("relative flex h-full items-center whitespace-nowrap px-1 transition-all", theme === "primary" && "font-theme-bold", theme === "white" && "text-inherit after:absolute after:bottom-0 after:left-1/2 after:block after:h-0.5 after:w-0 after:-translate-x-1/2 after:bg-secondary after:transition-all after:content-[''] hover:text-secondary")} key={key}>
                  <NavigationDropdown menuItem={menuItem} theme={theme} />
                </li>;
          }
          return <li className={clsx("relative flex h-full shrink items-center whitespace-nowrap px-1 transition-all", theme === "primary" && "font-theme-bold text-inherit hover:text-primary", theme === "white" && "text-inherit after:absolute after:bottom-0 after:left-1/2 after:block after:h-0.5 after:w-0 after:-translate-x-1/2 after:bg-secondary after:transition-all after:content-[''] hover:text-secondary", isActive && theme === "primary" && "bg-primary text-white hover:bg-primary-hover hover:text-white", isActive && theme === "white" && "font-theme-bold text-white after:!w-full after:bg-white hover:after:bg-secondary")} key={key}>
                <MenuItem className="h-full w-full text-inherit" position={`nav${index + 1}`} targetUrl={url}>
                  {name}
                </MenuItem>
              </li>;
        })}
        </ul>

        <ToolbarMenu className={clsx("relative flex-nowrap before:pointer-events-none before:absolute before:-left-2 before:top-0 before:block before:h-full before:w-2", theme === "primary" && "bg-white before:bg-gradient-to-l before:from-white", theme === "white" && "bg-primary before:bg-gradient-to-l before:from-primary")} theme={theme} data-sentry-element="ToolbarMenu" data-sentry-source-file="Navigation.component.tsx" />
      </div>
    </nav>;
}